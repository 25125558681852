@import "@/assets/scss/style.scss";

.c-cms-show {
    padding: 24px;
    width: 46%;
    margin: 16px auto;
    border-radius: 10px;
    background-color: $white-two;
    align-items: center;
}

.ant-menu-horizontal {
    line-height: 40px;
    white-space: nowrap;
    border: 0;
    /* border-bottom: 1px solid #e8e8e8; */
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 13.5px;
    color: #8c8c8c;
}

.menu-container {
    margin: 5px;
    margin-left: 5%;
}

.ant-menu-item {
    padding: 0 10px;
    margin: 10px;
}
